















































import { defineComponent } from "@vue/composition-api";

import setPageTitle from "@/compositions/setPageTitle";
import LastUpdatedAt from "@/components/LastUpdatedAt.vue";
import PageHeader from "@/components/PageHeader.vue";
import systemRepository from "@/repositories/systemRepository";

import { DocumentUiDto } from "@/models/DocumentUiDto";

interface License {
  key: string;
  url: string;
}
export default defineComponent({
  name: "TermsOfUse",

  components: {
    LastUpdatedAt,
    PageHeader,
  },

  setup() {
    setPageTitle("views.terms.pageTitle");
  },

  async beforeMount() {
    this.loading = true;
    try {
      const documents = await systemRepository.fetchLicenseDocs();
      this.documents = documents;
    } catch (err) {
      console.error(err);
    }
    this.loading = false;
  },

  data() {
    return {
      documents: [] as DocumentUiDto[],
      loading: false,
    };
  },

  computed: {
    licenses(): License[] {
      return [
        { key: "views.terms.docker.consul", url: "https://hub.docker.com/_/consul" },
        { key: "views.terms.docker.vernemq", url: "https://vernemq.com/" },
        { key: "views.terms.docker.mssql", url: "https://hub.docker.com/_/microsoft-mssql-server" },
        { key: "views.terms.docker.traefik", url: "https://hub.docker.com/_/traefik" },
      ];
    },
  },
});
